import { HudGlobal } from "../../util/HudGlobal";
import planApi from "../../api/planApi";
import { Constants } from "../../util/Constants";
import hoursApi from "../../api/hours/hoursApi";
import OfflineParam from "../../util/OfflineParam";
import ArrayHelper from "../../util/helper/ArrayHelper";
import DeviceHelper from "../../util/helper/DeviceHelper";

import HomeGuideSteps from "@/util/guide-step/HomeGuideSteps";
import GuideStepHelper from "@/util/guide-step/GuideStepHelper";
import ConstantsGuideCode from "@/util/constants/ConstantsGuideCode";
import TBDriver from "@tibi/vue-driver/TBDriver.js";
import StoreDBManger from "../../util/StoreDBManger";
import TibiMatomoManger from "../../util/manager/TibiMatomoManger";
import TrackConstants from "../../util/constants/TrackConstants";
import OverTrainingDialog from "./components/over-training-dialog/OverTrainingDialog.vue";

import studyPrepare from "../mixins/studyPrepare";
import StoreTrainDBManger from "../../db/StoreTrainDBManger";

export default {
    name: "PrepareTraining",
    mixins: [studyPrepare],
    components: {
        OverTrainingDialog,
    },
    data() {
        return {
            isFirstEntry: true, // 是否是第一次进入页面
            // 课件列表
            courseDataList: [],
            // 课件选中的ID数组
            checkedData: [],
            defaultProps: {
                children: "children",
                label: "courseName",
            },
        };
    },
    created() {
        this.isFirstEntry = true;
        this.$message.closeAll();
        this.dialogTrainObject.visible = false;
        let query = this.$route.query;
        Object.assign(this.routeParam, query);

        // 全局界面标识 plan prepare sign play
        window.localStorage.setItem("currentStep", "prepare");

        const that = this;

        // 获取计划信息
        StoreTrainDBManger.getCurrentPlanItemFromDB((item) => {
            item && (that.planData.planBaseInfoDto = item);
        });

        // 加载数据
        that.getPlanOne();
        that.getPlanCourse();
    },
    methods: {
        /*
         * 课件数据
         * */
        getPlanCourse() {
            let params = {
                planId: this.routeParam.planId,
            };
            let _this = this;
            planApi.getPlanCourseForPrepare(params).then((res) => {
                if (res.success && res.data) {
                    let data = res.data;
                    _this.courseDataList = data;
                    // 需要让页面渲染一下,
                    setTimeout(function() {
                        _this.courseListSelectedAll();
                    }, 500);
                } else {
                    if (res.code !== 102) {
                        // HudGlobal.showMessage("获取课件数据失败!");
                    }
                }
            });
        },
        /**
         * 处理 监督人培训教室的默认值 V1.0.9 lzb 2021-06-17
         */
        async handleLeaderListDefaultValue(data) {
            const classRoomDto = data.classRoomDto; // 人员信息
            const that = this;
            //  1. 设置默认教室
            if (classRoomDto && classRoomDto.classroomName && classRoomDto.state === 1) {
                this.planData.classRoomDto = classRoomDto;
            } else {
                // 请求获取第一个教室
                this.$refs.selectRoom &&
                    this.$refs.selectRoom.loadClassRoomList(function(dataList) {
                        if (dataList && dataList.length > 0) {
                            const room = dataList[0];
                            that.planData.classRoomDto = room;
                        }
                    });
            }

            //  2. 设置默认监督人
            const requestParam = this.params || {};
            const param = {
                orgId: that.$store.getters.orgId,
                identityCode: null,
                currentPage: 5,
            };
            Object.assign(requestParam, param);
            for (let i = 0; i < this.planData.planLeaderList.length; i++) {
                const item = this.planData.planLeaderList[i];
                if (!item.realName || item.realName.length == 0) {
                    requestParam.identityCode = item.leaderId;
                    const leaderList = await planApi.getPlanLeaders(requestParam);
                    if (leaderList && leaderList.length > 0) {
                        const leader = leaderList[0];
                        item.leaderId = leader.leaderId;
                        item.userId = leader.userId;
                        item.realName = leader.realName;
                        item.cardNo = leader.cardNo;
                    }
                }
            }
        },
        //================================================================课件处理==============================================================//
        /*
         * 课件全选
         * */
        courseListSelectedAll() {
            this.$refs.courseListBox && this.$refs.courseListBox.setCheckedNodes(this.courseDataList);
        },

        /*
         * 选中的范围变化, 带是否选中, 节点数据, 节点是否被选中(true选,false不选),
         * childNodeChecked子节点是否被选中(true选,false不选)
         * */
        checkRange(nodeData, nodeChecked, childNodeChecked) {
            this.handleSelectTreeData();
        },

        /*
         * 获取所有选中的课程列表
         * */
        getAllSelectedCourseData() {
            let allSelectedData = this.$refs["courseListBox"].getCheckedNodes(false, true);
            let allSelectedDataStr = JSON.stringify(allSelectedData);
            let parse = JSON.parse(allSelectedDataStr);
            return parse;
        },

        /*
         * 处理课程树的数据
         * */
        handleSelectTreeData() {
            // 获取所有选中的数据列表(数组)
            let allSelectedData = this.getAllSelectedCourseData();
            // 提取为ID数组
            let idArr = allSelectedData.map(function(data) {
                return data.courseId;
            });

            // 获取树的数据
            let newAllData = JSON.parse(JSON.stringify(this.courseDataList));

            // 移除不必要的数据
            this.removeData(newAllData, idArr);

            // 将数据赋给已选中课程树
            this.courseDataSelectedList = newAllData;
        },

        /*
         * 根据已经选中的数据, 删除未选中的数据
         * */
        removeData(data, idArr) {
            for (let i = data.length - 1; i >= 0; i--) {
                let datum = data[i];
                if (!idArr.includes(datum.courseId)) {
                    data.splice(i, 1);
                } else {
                    if (datum.children) {
                        this.removeData(datum.children, idArr);
                    }
                }
            }
        },

        /*
         * 移除选中课程的节点,
         * */
        removeSelectedCourse(node, data) {
            this.$refs.courseListBox.setChecked(data.courseId, false, true);
        },

        /**
         * @description:
         * @param {*} typeCode 管理监督员是否必选
         * @return {*}
         */
        validatePlanLeader(value) {
            const that = this;
            // offlineLeaderSigninEnable 总控制
            if (!OfflineParam.params.offlineLeaderSigninEnable) {
                return false;
            }
            return that.getOfflineEnable(value.typeCode);
        },

        // ======================================操作引导=============================================//
        /**
         * 检测操作引导
         */
        checkLivelearningOperationGuide() {
            if (this.isFirstEntry) {
                this.isFirstEntry = false;
                const that = this;
                GuideStepHelper.loadOperationGuideData(ConstantsGuideCode.prepareTrainGuideCodes, function(dataList) {
                    if (dataList == null || dataList.length == 0) {
                        return;
                    }
                    for (let i = 0; i < dataList.length; i++) {
                        const guide = dataList[i];
                        //是否已完成引导(0:未完成,1:已完成)
                        if (guide.guideDone == 0) {
                            const keyCode = guide.guideCode;

                            if (keyCode == "step201") {
                                TBDriver.addDriverStep(
                                    HomeGuideSteps.createLivelearningGuideSteps(
                                        "#step201",
                                        "确定人员",
                                        "1、点击【更换人员】，选择或更换安全管理员、考核人员、授课人 <br> 2、点击【培训教室】，选择或更换教室",
                                        null,
                                        201,
                                        201,
                                        false,
                                        true,
                                        false,
                                        ConstantsGuideCode.prepareTrainGuideCodes
                                    )
                                );
                            }
                            if (keyCode == "step202") {
                                TBDriver.addDriverStep(
                                    HomeGuideSteps.createLivelearningGuideSteps(
                                        "#step202",
                                        "培训课程",
                                        "勾选课程",
                                        null,
                                        202,
                                        202,
                                        false,
                                        true,
                                        true,
                                        ConstantsGuideCode.prepareTrainGuideCodes
                                    )
                                );
                            }

                            if (keyCode == "step203") {
                                TBDriver.addDriverStep(
                                    HomeGuideSteps.createLivelearningGuideSteps(
                                        "#step203",
                                        "开始培训",
                                        "点击[开始培训]，开始签到验证",
                                        null,
                                        203,
                                        203,
                                        that.planData.offlineRecordDtoList.length > 0 ? false : true,
                                        true,
                                        true,
                                        ConstantsGuideCode.prepareTrainGuideCodes
                                    )
                                );
                            }

                            // lzb 新增204 - 2020-08-28
                            if (keyCode == "step204" && that.planData.offlineRecordDtoList.length > 0) {
                                TBDriver.addDriverStep(
                                    HomeGuideSteps.createLivelearningGuideSteps(
                                        "#step204",
                                        "培训记录",
                                        "1、点击【培训记录】，查看培训情况 <br> 2、点击【结束培训】，结束此次培训 <br> 3、点击【继续培训】，继续上次培训",
                                        null,
                                        204,
                                        204,
                                        true,
                                        true,
                                        true,
                                        ConstantsGuideCode.prepareTrainGuideCodes
                                    )
                                );
                            }
                        }
                    }
                    TBDriver.startDriver();
                });
            }
        },
    },
};
