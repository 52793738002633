var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "container prepare" },
    [
      _c("div", { staticClass: "left" }, [
        _vm.planData && _vm.planData.planBaseInfoDto
          ? _c("div", { staticClass: "train-paln-info" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.planData.planBaseInfoDto.planName))
              ]),
              _c("div", { staticClass: "info-wrap" }, [
                _c("ul", { staticClass: "plan-list" }, [
                  _c("li", [
                    _vm._v(
                      " 计划周期：" +
                        _vm._s(
                          _vm._f("timeFormatFilter")(
                            _vm.planData.planBaseInfoDto.startTime
                          )
                        ) +
                        " 至 " +
                        _vm._s(
                          _vm._f("timeFormatFilter")(
                            _vm.planData.planBaseInfoDto.endTime
                          )
                        ) +
                        " "
                    )
                  ]),
                  _c("li", [
                    _vm._v(
                      "参加人员：" +
                        _vm._s(_vm.planData.planBaseInfoDto.userCount) +
                        "人"
                    )
                  ]),
                  _c("li", [
                    _vm._v(
                      "所属组织：" +
                        _vm._s(_vm.planData.planBaseInfoDto.orgName)
                    )
                  ]),
                  _c("li", [
                    _vm._v(
                      " 培训进度：已完成" +
                        _vm._s(_vm.planData.planBaseInfoDto.studyFinishCount) +
                        "人， 剩余" +
                        _vm._s(
                          _vm.planData.planBaseInfoDto.userCount -
                            _vm.planData.planBaseInfoDto.studyFinishCount
                        ) +
                        "人 "
                    )
                  ])
                ]),
                _c("i", { staticClass: "line" }),
                _c(
                  "ul",
                  { staticClass: "manager-role", attrs: { id: "step201" } },
                  [
                    _vm._l(_vm.planData.planLeaderList, function(item, index) {
                      return _c(
                        "li",
                        { key: index },
                        [
                          _c("span", [
                            _vm.validatePlanLeader(item)
                              ? _c("span", { staticClass: "verify" }, [
                                  _vm._v("*")
                                ])
                              : _vm._e(),
                            _c(
                              "span",
                              {
                                staticClass: "lable",
                                staticStyle: { "text-align": "right" }
                              },
                              [_vm._v(_vm._s(item.leaderName) + "：")]
                            ),
                            _c("span", { staticClass: "name" }, [
                              _vm._v(" " + _vm._s(item.realName || "--") + " ")
                            ])
                          ]),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.onClickChangeLeader(item)
                                }
                              }
                            },
                            [_vm._v("更换人员")]
                          )
                        ],
                        1
                      )
                    }),
                    _c(
                      "li",
                      [
                        _c("span", [
                          _c("span", { staticClass: "verify" }, [_vm._v("*")]),
                          _c(
                            "span",
                            {
                              staticClass: "lable",
                              staticStyle: { "text-align": "right" }
                            },
                            [_vm._v("培训教室：")]
                          ),
                          _c("span", { staticClass: "name" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  (_vm.planData.classRoomDto &&
                                    _vm.planData.classRoomDto.classroomName) ||
                                    "--"
                                ) +
                                " "
                            )
                          ])
                        ]),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: { click: _vm.onClickChangeRoom }
                          },
                          [_vm._v("更换教室")]
                        )
                      ],
                      1
                    )
                  ],
                  2
                )
              ])
            ])
          : _vm._e(),
        _c("div", { staticClass: "class-select" }, [
          _c(
            "div",
            { staticClass: "choose", attrs: { id: "step202" } },
            [
              _c("div", { staticClass: "title" }, [_vm._v("培训课程")]),
              _c("el-tree", {
                ref: "courseListBox",
                attrs: {
                  data: _vm.courseDataList,
                  "node-key": "courseId",
                  "show-checkbox": "",
                  "default-expand-all": "",
                  props: _vm.defaultProps
                },
                on: { "check-change": _vm.checkRange }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "selected" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("已选中课程")]),
              _c("el-tree", {
                ref: "courseSelectedListBox",
                attrs: {
                  data: _vm.courseDataSelectedList,
                  "node-key": "courseId",
                  "default-expand-all": "",
                  props: _vm.defaultProps,
                  "expand-on-click-node": false
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function({ node, data }) {
                      return _c(
                        "span",
                        { staticClass: "custom-tree-node node-text" },
                        [
                          _c("span", { staticClass: "node-label" }, [
                            _vm._v(_vm._s(node.label))
                          ]),
                          _c(
                            "span",
                            { staticClass: "close" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: () =>
                                      _vm.removeSelectedCourse(node, data)
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-error",
                                    staticStyle: { color: "#999999" }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    }
                  }
                ])
              })
            ],
            1
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "right" },
        [
          _vm.isShowStartTrainButton
            ? _c(
                "el-button",
                {
                  staticClass: "start-button",
                  attrs: {
                    type: "primary",
                    loading: _vm.planDataExtra.startLoading,
                    id: "step203"
                  },
                  on: { click: _vm.onClickStartTrain }
                },
                [_vm._v("开始培训")]
              )
            : _vm._e(),
          _vm.planData.offlineRecordDtoList &&
          _vm.planData.offlineRecordDtoList.length > 0
            ? _c("div", { staticClass: "history-list-wrap" }, [
                _c("div", { staticClass: "title" }, [_vm._v("培训历史记录")]),
                _c(
                  "ul",
                  { staticClass: "history-list" },
                  _vm._l(_vm.planData.offlineRecordDtoList, function(
                    item,
                    index
                  ) {
                    return _c("li", { key: index }, [
                      _c(
                        "ul",
                        { staticClass: "info-list" },
                        [
                          _c("li", [
                            _vm._v(
                              " 第" +
                                _vm._s(item.trainingNumber || "次数错误") +
                                "次培训 "
                            ),
                            item.state == 1
                              ? _c("span", { staticClass: "status red" }, [
                                  _vm._v("进行中")
                                ])
                              : _vm._e(),
                            item.state == 2
                              ? _c("span", { staticClass: "status blue" }, [
                                  _vm._v("结束")
                                ])
                              : _vm._e()
                          ]),
                          _c("li", [
                            _vm._v("管理员：" + _vm._s(item.createUser))
                          ]),
                          _c("li", [
                            _vm._v("开始时间：" + _vm._s(item.startTime))
                          ]),
                          _c("li", [
                            _vm._v("结束时间：" + _vm._s(item.endTime))
                          ]),
                          _c("li", [
                            _vm._v(
                              "签到学员：已签到" +
                                _vm._s(item.userSignInCount) +
                                "人"
                            )
                          ]),
                          _vm._l(item.planLeaderDtoList, function(
                            obj,
                            objIndex
                          ) {
                            return _c("li", { key: objIndex }, [
                              _c("span", [
                                _vm._v(_vm._s(obj.leaderName) + ":")
                              ]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(obj.realName || `无${obj.leaderName}`)
                                )
                              ])
                            ])
                          }),
                          _c("li", [
                            _vm._v("培训教室：" + _vm._s(item.classroomName))
                          ]),
                          _c(
                            "li",
                            { staticClass: "btns", attrs: { id: "step204" } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "small" },
                                  on: {
                                    click: function($event) {
                                      return _vm.onClickTrainRecordEvent(item)
                                    }
                                  }
                                },
                                [_vm._v("培训记录")]
                              ),
                              item.state == 1
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "small",
                                        loading: _vm.planDataExtra.endLoading
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.onClickOfflineRecord(item)
                                        }
                                      }
                                    },
                                    [_vm._v("结束培训")]
                                  )
                                : _vm._e(),
                              item.state == 1
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { size: "small" },
                                      on: {
                                        click: function($event) {
                                          return _vm.onClickContinueTrain(item)
                                        }
                                      }
                                    },
                                    [_vm._v("继续培训")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ])
                  }),
                  0
                )
              ])
            : _vm._e()
        ],
        1
      ),
      _c("OverTrainingDialog", {
        attrs: {
          propDialogVisible:
            _vm.overTrainingDialogObject.overTrainingDialogVisible,
          propObject: _vm.overTrainingDialogObject,
          endOfflineRecordLoadding: _vm.planDataExtra.endLoading
        },
        on: {
          "update:propDialogVisible": function($event) {
            return _vm.$set(
              _vm.overTrainingDialogObject,
              "overTrainingDialogVisible",
              $event
            )
          },
          "update:prop-dialog-visible": function($event) {
            return _vm.$set(
              _vm.overTrainingDialogObject,
              "overTrainingDialogVisible",
              $event
            )
          },
          "update:endOfflineRecordLoadding": function($event) {
            return _vm.$set(_vm.planDataExtra, "endLoading", $event)
          },
          "update:end-offline-record-loadding": function($event) {
            return _vm.$set(_vm.planDataExtra, "endLoading", $event)
          },
          addOfflineRecordData: _vm.onPwdAfterStartTrain,
          endCurrentRecord: _vm.endCurrentRecord
        }
      }),
      _c("SelectLeaderDialog", {
        attrs: {
          visible: _vm.dialogLeaderObject.visible,
          planId: _vm.planData.planId,
          currentLeader: _vm.dialogLeaderObject.currentLeader
        },
        on: {
          "update:visible": function($event) {
            return _vm.$set(_vm.dialogLeaderObject, "visible", $event)
          },
          "get-data": _vm.monitorSelectLeaderObject
        }
      }),
      _c("SelectRoomDialog", {
        ref: "selectRoom",
        attrs: {
          visible: _vm.dialogClassroomObject.visible,
          currentRoom: _vm.dialogClassroomObject.currentRoom,
          planId: _vm.planData.planId
        },
        on: {
          "update:visible": function($event) {
            return _vm.$set(_vm.dialogClassroomObject, "visible", $event)
          },
          "get-data": _vm.onClickListClassroomSelected
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }